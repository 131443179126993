<template>
  <!-- 待审核 -->
  <div class="rent-car">
    <div class="tree">
      <el-tree
        :expand-on-click-node="false"
        :default-expand-all="true"
        :data="carTreeList"
        :render-content="renderContent"
        @node-click="checkedNodeTree"
      ></el-tree>
    </div>
    <div class="check-car">
      <div v-for="item in orderList" :key="item.id" class="card">
        <div class="addressMoneyInfo">
          <span style="font-size: 16px; font-weight: 600" class="text"
            >订单编号</span
          >
          <span class="money">￥{{ item.totalPrices }}</span>
        </div>
        <div style="padding: 0 16px; margin: 12px 0" class="label">
          {{ item.id }}
        </div>
        <div class="carTime">
          下单时间
          <span>{{ item.createTime }}</span>
        </div>
        <div class="addressBox">
          <div class="car-info">
            <img :src="item.carLogo" alt class="car" />
            <div class="info">
              <div class="carBox">
                <span class="car-name">{{
                  item.charteredVehicleModelTypeName
                }}</span>
              </div>
              <p class="car-main">{{ item.carInfo }}</p>
            </div>
          </div>
          <div class="car-time">
            <span>{{ item.getVehicleTime }}</span>
            <div class="pic">
              <img :src="right" alt />
              <span>{{ "共" + item.days + "天" }}</span>
            </div>
            <span>{{ item.returnVehicleTime }}</span>
          </div>
          <div style="margin-bottom: 16px" class="car-address">
            <div style="background: #00c777" class="tips">取车点</div>
            <div
              class="get-renturn-address"
              @click="addressChangeFun(item.pickLocationInfo)"
            >
              <span class="location-name"> {{ item.pickLocationName }} </span>
              <i class="iconfont its_sm_dakaiditu"></i>
            </div>
          </div>
          <div class="car-address">
            <span style="background: #ff8627" class="tips">还车点</span>
            <span
              class="get-renturn-address"
              @click="addressChangeFun(item.returnLocationInfo)"
            >
              <span class="location-name"> {{ item.returnLocationName }} </span>
              <i class="iconfont its_sm_dakaiditu"></i>
            </span>
          </div>
        </div>
        <h3>费用信息</h3>
        <div class="row">
          <span class="text">租车押金：</span>
          <span class="money">￥{{ item.vehicleDeposit }}</span>
        </div>
        <div class="row">
          <span class="text">违章押金：</span>
          <span class="money">￥{{ item.violationDeposit }}</span>
        </div>
        <h3>承租人信息</h3>
        <div class="row">
          <span class="text">承租人：</span>
          <span class="label">{{ item.rentalDriverName }}</span>
        </div>
        <div class="row">
          <span class="text">身份证：</span>
          <span class="label">{{ item.idCard }}</span>
        </div>
        <div class="row">
          <span class="text">联系电话：</span>
          <span class="label">{{ item.rentalDriverPhone }}</span>
        </div>
        <div class="btnBox">
          <el-button
            v-if="listFind('证照审核')"
            size="small"
            @click="checkFun(item)"
            >证照审核</el-button
          >
        </div>
      </div>
    </div>
    <div v-if="mapShow" class="mapMask">
      <div class="header">
        <div
          class="iconfont its_guanbi"
          @click="
            () => {
              mapShow = false;
            }
          "
        ></div>
      </div>
      <div id="Map" class="Map"></div>
    </div>
    <el-dialog
      v-if="checkShow"
      :visible.sync="checkShow"
      title="证照审核"
      width="1200px"
      :close-on-click-modal="false"
      @close="checkCancel"
    >
      <div class="check-content">
        <div>
          <h2>证照信息</h2>
          <div class="content">
            <div class="row">
              <div class="item">
                <label>承租人姓名：</label>
                <p>{{ checkObj.userName }}</p>
              </div>
              <div class="item">
                <label>手机号码：</label>
                <p>{{ checkObj.phone }}</p>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <label>身份证号：</label>
                <p>{{ checkObj.idCard }}</p>
              </div>
              <div class="item">
                <label>有效期限：</label>
                <p>
                  {{ checkObj.identifyIdCardStartTime }} 至
                  {{ checkObj.identifyIdCardNoEndTime }}
                </p>
                <span v-if="checkObj.identifyStatus">有效期小于60天</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <label>驾驶证号：</label>
                <p>{{ checkObj.drivingLicence }}</p>
              </div>
              <div class="item">
                <label>准驾车型：</label>
                <p>{{ checkObj.drivingLicenceClass }}</p>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <label>初次领证日期：</label>
                <p>{{ checkObj.drivingLicenceIssueTime }}</p>
              </div>
              <div class="item">
                <label>有效期限：</label>
                <p>
                  {{ checkObj.drivingLicenceStartTime }} 至
                  {{ checkObj.drivingLicenceEndTime }}
                </p>
                <span v-if="checkObj.drivingStatus">有效期小于60天</span>
              </div>
            </div>
            <div class="photo">
              <el-image
                v-for="(item, index) in photoList"
                :key="index"
                :src="item"
                style="width: 240px; height: 130px"
                :preview-src-list="srcList"
                @click="clickPhoto(item)"
              />
            </div>
          </div>
        </div>
        <div>
          <h2>审核信息</h2>
          <div class="check-text">
            <label> <span style="color: red">*</span> 审核意见： </label>
            <el-input
              v-model="checkText"
              :rows="3"
              type="textarea"
              :show-word-limit="true"
              :maxlength="100"
              placeholder="审核不通过时，平台自动将此订单退订，审核结果会以短信的方式发送给用户"
            ></el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-if="listFind('审核不通过')"
          type="danger"
          @click="noPassFun"
          >审核不通过，并退订</el-button
        >
        <el-button
          v-if="listFind('审核通过')"
          type="success"
          @click="checkPassFun"
          >审核通过</el-button
        >
        <el-button @click="checkCancel">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="noPass"
      :visible.sync="noPass"
      title="退订"
      width="500px"
      :close-on-click-modal="false"
      @close="noPass = false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="130px"
        label-position="right"
      >
        <el-form-item label="退单原因：">
          <el-input
            v-model="form.auditingRemark"
            type="textarea"
            :maxlength="20"
            disabled
            :rows="3"
            placeholder="请输入退单原因"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信预览：">
          <el-input
            v-model="serviceNote"
            disabled
            type="textarea"
            :rows="4"
          ></el-input> </el-form-item
      ></el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="noPass = false">取 消</el-button>
        <el-button type="primary" @click="refundFun">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  waitAuditingScheduleAPI,
  licenceAuditingOrderAPI,
} from "@/api/lib/api.js";
import right from "@/assets/images/home/right.png";
import host from "@/api/static/host.js";
export default {
  data() {
    return {
      carTreeList: [],
      orderList: [],
      mapShow: false,
      map: null,
      checkShow: false,
      photoList: [],
      srcList: [],
      right: right,
      checkText: "",
      noPass: false,
      checkObj: {},
      form: {
        orderId: null,
        auditingRemark: null,
        auditingResult: null,
      },
      serviceNote: null,
    };
  },
  mounted() {
    this.renderData();
  },
  methods: {
    decryptDes(message, key = "inheritech_key") {
      var cryptoJs = require("crypto-js");
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    renderData() {
      this.orderList = [];
      waitAuditingScheduleAPI().then((res) => {
        if (res.code == "SUCCESS") {
          let list = [];
          let list2 = [];
          res.data.forEach((item) => {
            let arrays = [];
            item.children.forEach((jump) => {
              arrays.push({
                label: jump.nodeName,
                nodeOrderCount: jump.nodeOrderCount,
                id: jump.pickLocationId,
                rentalOrders: jump.rentalOrders,
              });
            });
            list.push({
              label: item.nodeName,
              children: arrays,
              nodeOrderCount: item.nodeOrderCount,
              id: item.pickLocationId,
              rentalOrders: item.rentalOrders,
            });
            if (item.rentalOrders.length > 0) {
              list2 = item.rentalOrders.map((e) => {
                let info = e.rentalVehicleModel || false;
                if (info) {
                  e.carInfo = `${info.gearbox} ${info.displacement}${
                    info.intakeFormCode == "WLZY" ? "T" : "L"
                  }/${info.carBodyStructure}/${info.vehicleTypeName}`;
                }
                e.idCard = this.decryptDes(e.idCardNo).replace(
                  /^(.{4})(?:\d+)(.{3})$/,
                  "$1******$2"
                );
                e.carLogo = host.imgURL + e.rentalVehicleModel.picture;
                return { ...e };
              });
              this.orderList.push(...list2);
            }
          });
          this.carTreeList = list;
        }
      });
    },
    renderContent(h, { node, data, store }) {
      if (data.nodeOrderCount) {
        return (
          <span>
            <span>{data.label}</span>
            <span style="color:red"> ({data.nodeOrderCount})</span>
          </span>
        );
      } else {
        return <span>{data.label}</span>;
      }
    },
    checkedNodeTree(data) {
      let list = [];
      this.orderList = [];
      if (data.rentalOrders && data.rentalOrders.length > 0) {
        list = data.rentalOrders.map((e) => {
          let info = e.rentalVehicleModel || false;
          if (info) {
            e.carInfo = `${info.gearbox} ${info.displacement}${
              info.intakeFormCode == "WLZY" ? "T" : "L"
            }/${info.carBodyStructure}/${info.vehicleTypeName}`;
          }
          e.idCard = this.decryptDes(e.idCardNo).replace(
            /^(.{4})(?:\d+)(.{3})$/,
            "$1******$2"
          );
          e.carLogo = host.imgURL + e.rentalVehicleModel.picture;
          return { ...e };
        });
      }
      this.orderList.push(...list);
    },
    checkFun(item) {
      this.checkShow = true;
      this.form.orderId = item.id;
      this.$nextTick(() => {
        this.checkObj = item.rentalDriverInfo;
        this.checkObj.drivingLicenceStartTime =
          this.checkObj.drivingLicenceStartTime.slice(0, 10);
        this.checkObj.drivingLicenceEndTime =
          this.checkObj.drivingLicenceEndTime.slice(0, 10);
        this.checkObj.drivingLicenceIssueTime =
          this.checkObj.drivingLicenceIssueTime.slice(0, 10);
        this.checkObj.identifyIdCardStartTime =
          this.checkObj.identifyIdCardStartTime.slice(0, 10);
        this.checkObj.identifyIdCardNoEndTime =
          this.checkObj.identifyIdCardNoEndTime.slice(0, 10);
        const date1 = new Date(this.checkObj.drivingLicenceEndTime);
        if (date1.getTime() < Date.now() + 60 * 24 * 3600 * 1000)
          this.checkObj.drivingStatus = true;
        const date2 = new Date(this.checkObj.identifyIdCardNoEndTime);
        if (date2.getTime() < Date.now() + 60 * 24 * 3600 * 1000)
          this.checkObj.identifyStatus = true;
        this.checkObj.idCard = this.decryptDes(this.checkObj.idCardNo).replace(
          /^(.{4})(?:\d+)(.{3})$/,
          "$1******$2"
        );
        this.checkObj.drivingLicence = this.decryptDes(
          this.checkObj.drivingLicenceNo
        ).replace(/^(.{4})(?:\d+)(.{3})$/, "$1******$2");
        this.photoList = [
          host.imgURL + this.checkObj.identifyIdCardNoFrontPhoto,
          host.imgURL + this.checkObj.identifyIdCardNoReversePhoto,
          host.imgURL + this.checkObj.drivingLicenceFrontPhoto,
          host.imgURL + this.checkObj.drivingLicenceReversePhoto,
        ];
      });
    },
    // 实例化地图
    initMap(lon, lat) {
      this.map = new AMap.Map("Map", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        center: [lon, lat],
        zoom: 10,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      var marker = new AMap.Marker({
        offset: new AMap.Pixel(-18, -18), //设置偏移量
        position: new AMap.LngLat(lon, lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        icon: require("../../../../assets/images/home/passenger.png"),
      });
      this.map.add(marker);
      this.map.setFitView(marker);
    },
    // 点击详细地址的时候打开地图
    addressChangeFun(obj) {
      const { latitude, longitude } = obj;
      this.mapShow = true;
      this.$nextTick(() => {
        this.initMap(longitude, latitude);
      });
    },
    clickPhoto(val) {
      this.srcList = [val];
    },
    noPassFun() {
      if (this.checkText) {
        this.noPass = true;
        this.form.auditingRemark = this.checkText;
        this.form.auditingResult = false;
        const phone = sessionStorage.getItem("phone");
        this.serviceNote = `【广运神马】您的租车订单${this.form.orderId}已被平台取消，原因：${this.checkText}。您支付的金额将在3个工作日退回您的账户，如有疑问，请联系客服：${phone}`;
      } else this.$message.warning("请输入审核意见");
    },
    checkPassFun() {
      if (this.checkText) {
        this.form.auditingResult = true;
        this.form.auditingRemark = this.checkText;
        licenceAuditingOrderAPI(this.form).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("审核成功！");
            this.checkShow = false;
            this.renderData();
          }
        });
      } else this.$message.warning("请输入审核意见");
    },
    checkCancel() {
      this.checkShow = false;
      this.checkText = "";
      this.form = {
        orderId: null,
        auditingRemark: null,
        auditingResult: null,
      };
      this.serviceNote = null;
    },
    refundFun() {
      licenceAuditingOrderAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("退订成功！");
          this.noPass = false;
          this.checkShow = false;
          this.renderData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rent-car {
  height: 100%;
  background: #f7f8f9;
  display: flex;
  .mapMask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    top: 0;
    left: 0;
    z-index: 100;
    .Map {
      width: 100%;
      height: calc(100% - 40px);
    }
    .header {
      height: 40px;
      box-shadow: 0px 2px 4px 0px #b4b2b2;
      .iconfont {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .tree {
    width: 250px;
    margin-right: 20px;
    box-shadow: 0px 0px 1px 0px #b4b2b2;
    transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -webkit-transition: box-shadow 0.3s;
    height: 100%;
    background: #ffffff;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    /deep/.el-tree-node {
      white-space: normal;
      .el-tree-node__content {
        height: 100%;
      }
    }
  }
  .check-car {
    flex: 1;
    height: 100%;
    border: 1px solid #dbe2f3;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow-y: auto;
    .card {
      border-radius: 2px;
      border: 1px solid #e9e9e9;
      background: #ffffff;
      width: 290px;
      // width: calc((100% - 80px) / 4);
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      min-height: 605px;
      .addressBox {
        padding: 16px 12px;
        background: #f3f5fa;
        margin: 12px 0 16px 0;
        .car-cph {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          & > div:nth-child(1) {
            background: #336ffe;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            padding: 6px 10px;
            margin-right: 8px;
          }
          & > div:nth-child(2) {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
          }
        }
        .car-info {
          display: flex;
          align-items: center;
          .car {
            width: 73px;
            height: 34px;
          }
          .info {
            margin-left: 8px;
            flex: 1;
            display: flex;
            flex-direction: column;
            .car-name {
              color: #333333;
              font-weight: bold;
              margin-bottom: 12px;
            }
            .car-main {
              font-size: 12px;
              color: #999;
            }
          }
        }
        .car-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          margin: 20px 0 16px;
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          .pic {
            position: relative;
            width: 68px;
            img {
              width: 50px;
            }
            span {
              display: inline-block;
              text-align: center;
              width: 100%;
              position: absolute;
              left: -5px;
              bottom: 6px;
              color: #336ffe;
              font-size: 10px;
            }
          }
        }
        .car-address {
          display: flex;
          align-items: flex-start;
          .tips {
            display: inline-block;
            padding: 3px 8px;
            background: #336ffe;
            color: #fff;
            font-size: 12px;
            border-radius: 2px;
          }
          .get-renturn-address {
            margin: 0 6px;
            display: flex;
            cursor: pointer;
            .location-name {
              display: inline-block;
              width: 185px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
          .its_sm_dakaiditu {
            color: #448bfb;
            font-size: 12px;
            margin-left: 2px;
            position: relative;
            top: 1px;
          }
        }
      }
      .btnBox {
        width: calc(100% - 24px);
        border-top: 1px solid #f2f2f2;
        padding: 12px;
        background: #ffffff;
        margin-top: 12px;
        position: absolute;
        text-align: center;
        bottom: 0;
        .el-button:first-child {
          color: #333333;
          border: 1px solid #dcdedf;
        }
        .el-button:last-child {
          background: #336ffe;
          color: #ffffff;
          border: 1px solid #336ffe;
        }
      }
      h3 {
        padding: 0 15px;
      }
      .row {
        padding: 0 16px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        .text {
          color: #86888b;
          font-size: 12px;
          font-weight: 400;
          width: 60px;
          text-align: right;
          display: inline-block;
        }
        .label {
          display: inline-block;
          width: calc(100% - 60px);
          color: #333333;
          font-size: 14px;
          font-weight: 400;
        }
        .money {
          display: inline-block;
          width: calc(100% - 60px);
          color: rgb(255, 166, 0);
          font-size: 16px;
          font-weight: 400;
        }
      }
      .carTime {
        font-weight: 400;
        color: #336ffe;
        font-size: 14px;
        padding: 0 16px;
      }
      .addressMoneyInfo {
        margin-top: 22px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 16px;
        .money {
          font-weight: 600;
          color: #ff8627;
          font-size: 16px;
        }
      }
    }
  }
  .check-content {
    padding: 0 20px;
    .check-text {
      display: flex;
      padding: 0 20px;
      box-sizing: border-box;
      label {
        width: 105px;
        text-align: right;
        margin-top: 8px;
      }
    }
    .content {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      .row {
        display: flex;
        margin-bottom: 20px;
        .item {
          flex: 1;
          font-size: 15px;
          display: flex;
          align-items: center;
          label {
            width: 105px;
            text-align: right;
          }
          p {
            width: 250px;
            border: 1px solid #e0e0e0;
            padding: 10px;
            border-radius: 5px;
          }
          span {
            color: red;
            margin-left: 20px;
          }
        }
      }
      .photo {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
